const moment = require('moment');

export var FileDownload = {
  fetch: function(urlString, label='download', searchParams={}, callbacks={success: null, failure: null}) {
    var url = new URL(urlString);
    url.search = $.param(searchParams);
    var filename = 'ember_download__' + moment().format('YYYY_MM_DD__HH_mm_ss');  // Default filename

    fetch(url)
    .then(resp => {
      console.log(resp.status);
      if (resp.status == 200) {
        var contentDisposition = resp.headers.get('Content-Disposition');
        if(contentDisposition) {
          contentDisposition.split(';').forEach(dispositionEntry => {
            var val = dispositionEntry.split('=')[1];
            if (val) {                                                            // Override default filename based on server response
              if (val.toLowerCase().startsWith("utf-8''")){
                filename = decodeURIComponent(val.replace(/utf-8''/i, ''));
              } else {
                filename = val.replace(/['"]/g, '');
              }
            }
          });
        }
      } else {throw new Error('Invalid server response');}
      return resp.blob();
    })
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      FileDownload.callback(callbacks, 'success');
    })
    .catch(() => {FileDownload.callback(callbacks, 'failure');});
  },

  callback: function(callbacks, type) {
    if (callbacks[type]) {callbacks[type]();}
  }
}
