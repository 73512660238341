require('jquery-ui/dist/jquery-ui.min.js')
require('jquery-datetimepicker')
import 'jquery-datetimepicker/jquery.datetimepicker.css'
const moment = require('moment')

$.datetimepicker.setDateFormatter({
  parseDate: function (date, format) {
    var d = moment(date, format);
    return d.isValid() ? d.toDate() : false;
  },

  formatDate: function (date, format) {
    return moment(date).format(format);
  }
});

export const DateTimePicker = {
  init: function() {
    DateTimePicker.enableDateTimePickers();
    DateTimePicker.mutationObserver.observe(document.querySelector("#Content"), { subtree: true, childList: true });
  },
  
  enableDateTimePickers: function() {
    $('.datetimepicker').each(function(index, element) {
      var enabled = $(element).data('datetimepicker-enabled');
      if (enabled !== true) {
        $(element).datetimepicker({
          format: 'YYYY/MM/DD h:mma',
          formatDate: 'YYYY/MM/DD',
          formatTime: 'h:mma',
          step: 15,
          closeOnDateSelect: true,
          onSelectDate: function(ct, $i) {
            DateTimePicker.dateOrTimeSelected($i, ct, 'YYYY/MM/DD h:mma');
          },
          onSelectTime: function(ct, $i) {
            DateTimePicker.dateOrTimeSelected($i, ct, 'YYYY/MM/DD h:mma');
          }
        })
        $(element).data('datetimepicker-enabled', true);
      }
    });
    $('.datepicker').each(function(index, element) {
      var enabled = $(element).data('datepicker-enabled');
      if (enabled !== true) {
        $(element).datetimepicker({
          timepicker: false,
          format: 'YYYY/MM/DD',
          formatTime: 'YYYY/MM/DD',
          closeOnDateSelect: true,
          onSelectDate: function(ct, $i) {
            DateTimePicker.dateOrTimeSelected($i, ct, 'YYYY/MM/DD');
          }
        })
        $(element).data('datepicker-enabled', true);
      }
    });
    $('.timepicker').each(function(index, element) {
      var enabled = $(element).data('timepicker-enabled');
      if (enabled !== true) {
        $(element).datetimepicker({
          datepicker: false,
          format: 'h:mma',
          formatTime: 'h:mma',
          showPeriod: true,
          step: 15,
          closeOnDateSelect: true,
          onSelectTime: function(ct, $i) {
            DateTimePicker.dateOrTimeSelected($i, ct, 'h:mma');
          }
        })
        $(element).data('timepicker-enabled', true);
      }
    });
  },

  dateOrTimeSelected: function($i, ct, fmt) {
    var momentObj = moment(ct);
    $i.attr('value', momentObj.format(fmt));
    var updatableIds = $i.data('update-on-change')
    if (updatableIds) {
      updatableIds.split(',').forEach(function(domId) {
        $('#' + domId).val(momentObj.add($i.data('update-offset'), 's').format(fmt));
      });
    }
  },

  mutationObserver: new MutationObserver(function(mutationsList) {
    var reenableDateTimePickers = false;
    mutationsList.forEach(function(mutation) {
      mutation.addedNodes.forEach(function(addedNode) {
        if($(addedNode).find('.datetimepicker')) {
          reenableDateTimePickers = true;
        }
      });
    });
    if (reenableDateTimePickers) {DateTimePicker.enableDateTimePickers();}
  })
}

// Reincorporate this if needed. Avoids page reposition when displaying the picker in a popup.
// beforeShow: function(input, inst) {
//             $('.datepicker_current_input').removeClass('datepicker_current_input');
//             $(input).addClass('datepicker_current_input');
//             return setTimeout(function() {
//               var top = $(".datepicker_current_input").offset().top + 35;
//               if ($('#Popup').is(':visible')) {top -= $(document).scrollTop();}
//               var left = $(".datepicker_current_input").offset().left;
//               inst.dpDiv.css({top: top, left: left});
//               return 0;
//             });
//           }

